import {  PrevPlatformProvider } from '@prev-front/ui-components'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import React, { Suspense, useEffect } from 'react'
import './reset.css'
import { ClientProvider } from './context/ClientContext'
import './styles.css'
import { NotificationProvider } from './context/NotificationContext'
import { AnimationHandlerProvider } from './context/AnimationHandlerContext'
import { ClientListProvider } from './context/ClientListContext'
import { UseSearchInterface } from '@prev-front/mf-template'

const Clientes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-clientes" */ './pages/Clientes'
    ).then(module => ({ default: module.Clientes }))
)

const ClientView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-clientView" */ './pages/ClientView'
    ).then(module => ({ default: module.ClientView }))
)

export interface RootProps {
  context: UseSearchInterface  & { scrollTop: () => void, checkPaywall: () => boolean }
}

export default function Root (props: RootProps): JSX.Element {
  useEffect(() => {
    const darkMode = localStorage.getItem('darkMode')
    if (darkMode === 'true') {
      document.getElementsByTagName('body')[0].style.backgroundColor = '#27272E'
    } else {
      document.getElementsByTagName('body')[0].style.backgroundColor = 'white'
    }
  }, [])

  return (
    <PrevPlatformProvider>
      <Suspense>
        <NotificationProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/clientes" Component={() => (
                    <ClientListProvider>
                      <AnimationHandlerProvider>
                        <Clientes searchContext={props?.context} />
                      </AnimationHandlerProvider>
                    </ClientListProvider>
                )} />
                <Route path="/clientes/:cpf" Component={() => (
                    <ClientProvider>
                      <AnimationHandlerProvider>
                        <ClientView />
                      </AnimationHandlerProvider>
                    </ClientProvider>
                )} />
              </Routes>
            </BrowserRouter>
        </NotificationProvider>
      </Suspense>
    </PrevPlatformProvider>

  )
}
