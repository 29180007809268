import { Row, Text, Button, usePrevTheme } from '@prev-front/ui-components'
import React from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'

interface Props {
  onClose: any
  title: string
  buttons?: [any]
}

export const Navbar = (props: Props): JSX.Element => {
  const { onClose, title, buttons } = props
  const { theme } = usePrevTheme()

  return (
    <Row
      style={{
        justifyContent: 'space-between',
        margin: 0,
        padding: '40px 20px',
        marginBottom: '20px',
        width: '100%',
        backgroundColor: theme.color.surface.container.default,
        zIndex: 50,
        height: '100px'
      }}
    >
      <Row
        style={{ gap: '20px', justifyContent: 'center', alignItems: 'center' }}
      >
        <Button type="subtle" onClick={onClose}>
          <Row style={{ gap: '5px' }}>
            <ArrowLeftOutlined />
            <Text>Voltar</Text>
          </Row>
        </Button>
        <Text size="h4" style={{ fontWeight: 600}}>{title}</Text>
      </Row>
      {buttons?.map((e) => e)}
    </Row>
  )
}
