import {
  JobsSelect,
  Label,
  Radio,
  message,
  Col,
  FieldInput,
  Form,
  Row,
  Text,
  Select,
  Card,
  Divider,
  type FormInstance,
  usePrevTheme,
  LocalLoading,
  useWidth,
  DatePicker
} from '@prev-front/ui-components'
import React, { useContext, useEffect, useState } from 'react'
import {
  type NewClient,
  createClient,
  updateClient,
  type Profissao,
  getEnderecoByCep,
  getJobs
} from '../../services'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ApiError } from '@prev-front/api-adapter'
import { ajustHifen, cleanObject, escolaridade, estados } from '../../utils'
import { NotificationContext } from '../../context/NotificationContext'
import { ClientContext } from '../../context/ClientContext'
import { AnimationHandlerContext } from '../../context/AnimationHandlerContext'
import { ClientListContext } from '../../context/ClientListContext'
import moment from 'moment'
import dayjs, { Dayjs } from 'dayjs'

interface Props {
  form: FormInstance<NewClient>
}

export const CreateClientForm = (props: Props): JSX.Element => {
  const { form } = props
  const [selectedJob, setSelectedJob] = useState<Profissao>()
  const { theme, darkMode } = usePrevTheme()
  const { api } = useContext(NotificationContext)
  const { clientData, fetchClient } = useContext(ClientContext)
  const { createClient: createClientModal } = useContext(
    AnimationHandlerContext
  )
  const { fetchClients, pagination } = useContext(ClientListContext)
  const [mainPhoneMask, setMainPhoneMask] = useState('(99) 99999-9999')
  const [secondaryPhoneMask, setSecondaryPhoneMask] =
    useState('(99) 99999-9999')
  const [cellPhoneMask, setCellPhoneMask] = useState('(99) 99999-9999')
  const [loading, setLoading] = useState(false)
  const width = useWidth()

  useEffect(() => {
    if (clientData) {
      form.setFieldsValue(clientData)
      handleMasks()
      if (clientData?.profissoes) {
        setSelectedJob(clientData.profissoes[0])
      }
    }
  }, [clientData])

  const fetchAddress = async (cep: string): Promise<void> => {
    try {
      const onlyNumbersCep = cep.replace(/\D/g, '')
      const endereco = await getEnderecoByCep(onlyNumbersCep)
      endereco.numero && delete endereco.numero
      props.form.setFieldsValue({ endereco })
    } catch (err) {}
  }

  const formatDayjs = (date: any) => {
    if(date?.$isDayjsObject) {
      return date?.format('YYYY-MM-DD')
    }
    return date
  } 

  const handleSubmit = async (): Promise<void> => {
    try {
      const dataValues = form.getFieldsValue()
      console.log("form values", dataValues)
      const withJobs = {
        ...dataValues,
        profissoes: [selectedJob],
        dataDeNascimento: formatDayjs(dataValues.dataDeNascimento),
        dataDeObito: formatDayjs(dataValues.dataDeObito)
      }
      const data = cleanObject(withJobs)
      setLoading(true)
      if (clientData) {
        await updateClient({
          payload: withJobs,
          cpf: clientData?.cpf
        })
        await fetchClient()
      } else {
        await createClient(data)
        await fetchClients({
          limite: pagination.pageSize,
          pagina: pagination.currentPage || 1
        })
        form.resetFields()
      }
      api.success({
        message: clientData
          ? 'Cliente editado com sucesso!'
          : 'Cliente cadastrado com sucesso!',
        placement: 'bottomRight'
      })
      setLoading(false)
      createClientModal.setIsVisibleCreate(false)
    } catch (err) {
      setLoading(false)
      api.error({
        message: err.message,
        placement: 'topRight'
      })
      if (!(err instanceof ApiError))
        void message.error('Algo deu errado, tente novamente mais tarde')
    }
  }

  const handleMasks = (): void => {
    clientData?.telefonePrincipal?.length === 15
      ? setMainPhoneMask('(99) 99999-9999')
      : setMainPhoneMask('(99) 9999-9999')
    clientData?.telefoneSecundario?.length === 15
      ? setSecondaryPhoneMask('(99) 99999-9999')
      : setSecondaryPhoneMask('(99) 9999-9999')
    clientData?.celular?.length === 15
      ? setCellPhoneMask('(99) 99999-9999')
      : setCellPhoneMask('(99) 9999-9999')
  }

  if (loading)
    return (
      <LocalLoading
        display={loading}
        style={{ height: '100vh', overflow: 'hidden' }}
      />
    )

  return (
    <Row
      style={{
        justifyContent: 'center',
        padding: `0px 20px 20px ${width <= 1200 ? '20px' : '130px'}`
      }}
      gutter={24}
    >
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={18}
        xxl={18}
        style={{ width: '100%', position: 'relative' }}
      >
        <Form
          name="newClientForm"
          requiredMark={false}
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
          scrollToFirstError={{
            behavior: 'smooth',
            block: 'center'
          }}
          onFinish={handleSubmit}
          form={form}
        >
        <Col span={24} style={{ marginBottom: '20px', paddingLeft: 0 }}>
          <Text style={{ margin: '20px 0' }}>
            Dados pessoais do cliente
          </Text>
          <Row align="middle" style={{ gap: '5px' }}>
            <InfoCircleOutlined style={{ color: theme.color.icon.danger }} />
            <Text size="default">Campos de preenchimento obrigatório</Text>
          </Row>
        </Col>
          <Row style={{ justifyContent: 'space-between' }} gutter={12}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="nome"
                label={
                <Row style={{ gap: "3px"}} justify='center' align='middle'>
                  <Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>
                    Nome completo
                  </Text>
                  <InfoCircleOutlined style={{ color: theme.color.icon.danger }} />
                </Row>
                }
                layout='vertical'
                required={false}
                rules={[
                  {
                    required: true,
                    message: 'Insira o nome do cliente'
                  }
                ]}
              >
                <FieldInput
                  required
                  name="nome"
                  placeholder="Digite o nome do cliente"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                label={
                  <Row style={{ gap: "3px"}} justify='center' align='middle'>
                    <Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>
                      CPF
                    </Text>
                    <InfoCircleOutlined style={{ color: theme.color.icon.danger }} />
                  </Row>
                }
                layout='vertical'
                required={false}
                name="cpf"
                validateDebounce={500}
                rules={[
                  {
                    required: true,
                    message: 'Insira o CPF do cliente'
                  },
                  {
                    pattern: /^(?:\d{3}\.?\d{3}\.?\d{3}-?\d{2}|\d{11})$/,
                    message: 'CPF inválido'
                  }
                ]}
              >
                <FieldInput
                  mask="999.999.999-99"
                  required
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
              <Form.Item
                name="sexo"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório'
                  }
                ]}
              >
                  <Row style={{ marginBottom: "13px"}}>
                    <Row style={{ gap: "3px"}} justify='center' align='middle'>
                      <Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>
                        Sexo
                      </Text>
                      <InfoCircleOutlined style={{ color: theme.color.icon.danger }} />
                    </Row>
                  </Row>
                  <Row>
                    <Form.Item name="sexo">
                      <Radio.Group>
                        <Radio value="MASCULINO">Masculino</Radio>
                        <Radio value="FEMININO">Feminino</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{ justifyContent: 'space-between' }}
            gutter={12}
          >
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Form.Item
              name="dataDeNascimento"
              label={
                <Row style={{ gap: "3px"}} justify='center' align='middle'>
                  <Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>
                    Data de nascimento
                  </Text>
                  <InfoCircleOutlined style={{ color: theme.color.icon.danger }} />
                </Row>
              }
              layout='vertical'
              required
              getValueProps={(value: any) => formatDayjs(value)}
              rules={[
                {
                  required: true,
                  message: 'A data de nascimento é obrigatória'
                }
              ]}
              style={{ margin: 0, padding: 0 }}
            >
              <DatePicker
                disabledDate={(current) => current && current >= new Date().setHours(0, 0, 0, 0)} 
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                placeholder="Data de nascimento"
              />
            </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <Form.Item
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Estado civil</Text>}
                layout='vertical'
                required={false}
                name="estadoCivil"
                style={{ margin: 0, padding: 0 }}
                initialValue={'SOLTEIRO'}
              >
                <Select
                  options={[
                    {
                      label: 'Solteiro',
                      value: 'SOLTEIRO'
                    },
                    {
                      label: 'Casado',
                      value: 'CASADO'
                    },
                    {
                      label: 'Viuvo',
                      value: 'VIUVO'
                    },
                    {
                      label: 'Divorciado',
                      value: 'DIVORCIADO'
                    },
                    {
                      label: 'Separado',
                      value: 'SEPARADO'
                    }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
            <Form.Item>
            <JobsSelect
                showLabel={true}
                selected={selectedJob}
                setSelected={setSelectedJob}
                getJobs={getJobs}
              />
            </Form.Item>
            </Col>
          </Row>
          <Text style={{ margin: '20px 0' }}>Dados de contato do cliente</Text>
          <Row style={{ justifyContent: 'space-between', marginBottom: "20px" }} gutter={12}>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <Form.Item
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Email</Text>}
                layout='vertical'
                required={false}
                style={{ margin: 0, padding: 0, width: '100%' }}
                name="email"
                validateDebounce={500}
                rules={[
                  {
                    type: 'email',
                    message: 'Email inválido'
                  }
                ]}
              >
                <FieldInput
                  placeholder="Digite o email do cliente"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Form.Item
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Celuar</Text>}
                layout='vertical'
                required={false}
                validateDebounce={500}
                name="celular"
                rules={[
                  {
                    message: 'Número inválido',
                    min: 14
                  }
                ]}
              >
                <FieldInput
                  style={{ width: '100%' }}
                  mask={cellPhoneMask}
                  onKeyDown={(event) => {
                    const { currentTarget } = event
                    if (
                      currentTarget.value.length > 14 &&
                      event.key === 'Backspace'
                    ) {
                      setTimeout(() => {
                        setCellPhoneMask('(99) 9999-9999')
                        props.form.setFieldValue(
                          'celular',
                          ajustHifen(currentTarget.value)
                        )
                      }, 100)
                    }
                  }}
                  onBeforeInput={(event) => {
                    if (event.currentTarget.value.length >= 14) {
                      setCellPhoneMask('(99) 99999-9999')
                    } else {
                      setCellPhoneMask('(99) 9999-9999')
                    }
                  }}
                  placeholder="Digite seu telefone"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Form.Item
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Telefone</Text>}
                layout='vertical'
                required={false}
                validateDebounce={500}
                name="telefonePrincipal"
                rules={[
                  {
                    message: 'Número inválido',
                    min: 14
                  }
                ]}
              >
                <FieldInput
                  style={{ width: '100%' }}
                  mask={mainPhoneMask}
                  onKeyDown={(event) => {
                    const { currentTarget } = event
                    if (
                      currentTarget.value.length > 14 &&
                      event.key === 'Backspace'
                    ) {
                      setTimeout(() => {
                        setMainPhoneMask('(99) 9999-9999')
                        props.form.setFieldValue(
                          'telefonePrincipal',
                          ajustHifen(currentTarget.value)
                        )
                      }, 100)
                    }
                  }}
                  onBeforeInput={(event) => {
                    if (event.currentTarget.value.length >= 14) {
                      setMainPhoneMask('(99) 99999-9999')
                    } else {
                      setMainPhoneMask('(99) 9999-9999')
                    }
                  }}
                  placeholder="Digite seu telefone"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <Form.Item
                validateDebounce={500}
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Telefone secundário</Text>}
                layout='vertical'
                required={false}
                name="telefoneSecundario"
                rules={[
                  {
                    message: 'Número inválido',
                    min: 14
                  }
                ]}
              >
                <FieldInput
                  style={{ width: '100%' }}
                  mask={secondaryPhoneMask}
                  onKeyDown={(event) => {
                    const { currentTarget } = event
                    if (
                      currentTarget.value.length > 14 &&
                      event.key === 'Backspace'
                    ) {
                      setTimeout(() => {
                        setSecondaryPhoneMask('(99) 9999-9999')
                        props.form.setFieldValue(
                          'telefoneSecundario',
                          ajustHifen(currentTarget.value)
                        )
                      }, 100)
                    }
                  }}
                  onBeforeInput={(event) => {
                    if (event.currentTarget.value.length >= 14) {
                      setSecondaryPhoneMask('(99) 99999-9999')
                    } else {
                      setSecondaryPhoneMask('(99) 9999-9999')
                    }
                  }}
                  placeholder="Digite o telefone"
                />
              </Form.Item>
            </Col>
          </Row>
          <Text style={{ margin: '20px 0' }}>Endereço do cliente</Text>
          <Row style={{ justifyContent: 'space-between', marginBottom: "20px" }} gutter={12}>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
            <Form.Item 
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>CEP</Text>}
                layout='vertical'
                required={false}
                name={['endereco', 'cep']}
            >
                <FieldInput mask='99999-999' onBlur={(event) => {
                    if (event?.target?.value.length >= 9) {
                      void fetchAddress(event.target.value)
                    }
                  }} placeholder='00000-000' 
                />
            </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item 
                name={['endereco', 'endereco']} 
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Rua/avenida</Text>}
                layout='vertical'
                required={false}
              >
                <FieldInput
                  placeholder="Digite o nome da rua ou avenida"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item 
                name={['endereco', 'numero']}
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Número</Text>}
                layout='vertical'
                required={false}
              >
                <FieldInput placeholder="Digite o número" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item 
                name={['endereco', 'complemento']}
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Complemento</Text>}
                layout='vertical'
                required={false}
              >
                <FieldInput placeholder="apt/sala/bloco" />
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{ justifyContent: 'space-between', marginBottom: '20px' }}
            gutter={12}
          >
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name={['endereco', 'bairro']}
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Bairro</Text>}
                layout='vertical'
                required={false}
              >
                <FieldInput placeholder="Digite o bairro" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item 
                name={['endereco', 'cidade']}
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Cidade</Text>}
                layout='vertical'
                required={false}
              >
                <FieldInput placeholder="Digite a cidade" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Estado</Text>}
                layout='vertical'
                required={false}
                name={['endereco', 'uf']}
              >
                <Select
                  options={estados}
                  placeholder="Selecione seu estado"
                />
              </Form.Item>
            </Col>
          </Row>
          <Text style={{ margin: '20px 0' }}>Outros dados e documentos</Text>
          <Row style={{ justifyContent: 'space-between', marginBottom: "10px" }} gutter={12}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="escolaridade"
                initialValue={escolaridade[0].value}
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Escolaridade</Text>}
                layout='vertical'
                required={false}
              >
                <Select
                  placeholder="Selecione a escolaridade"
                  dropdownStyle={{ zIndex: 9999999 }}
                  options={escolaridade.map((e) => ({
                    label: e.desc,
                    value: e.value
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="rg"
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>RG</Text>}
                layout='vertical'
                required={false}
                rules={[
                  { required: false, min: 8, message: 'Formato inválido' }
                ]}
              >
                <FieldInput
                  placeholder="00.000.000-0"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item 
                name="cnh"
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>CNH</Text>}
                layout='vertical'
                required={false}
              >
                <FieldInput placeholder="000000000000" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="ctps"
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>CTPS</Text>}
                layout='vertical'
                required={false}
                rules={[
                  { min: 16, required: false, message: 'Formato inválido' }
                ]}
              >
                <FieldInput
                  placeholder="000.000000.00-00"
                  mask="999.999999.99-99"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{ justifyContent: 'space-between', marginTop: '20px' }}
            gutter={12}
          >
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="nit"
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>NIT</Text>}
                layout='vertical'
                required={false}
                rules={[
                  { min: 14, required: false, message: 'Formato inválido' }
                ]}
              >
                <FieldInput
                  mask="999.99999.99-9"
                  placeholder="000.00000.00-0"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item
                name="nitSecundario"
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>NIT Secundário</Text>}
                layout='vertical'
                required={false}
                rules={[
                  { min: 14, required: false, message: 'Formato inválido' }
                ]}
              >
                <FieldInput
                  mask="999.99999.99-9"
                  placeholder="000.00000.00-0"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Form.Item 
                name="dataDeObito"
                label={<Text style={{ fontSize: "14px", color: theme.color.text.subtle}}>Data de óbito</Text>}
                layout='vertical'
                required={false}
                getValueProps={(value: any) => formatDayjs(value)}
              >
                <DatePicker   
                    disabledDate={(current) => current && current >= new Date().setHours(0, 0, 0, 0)} 
                    format="DD/MM/YYYY"
                    style={{ width: "100%"}} 
                    placeholder="Data de óbito"  
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col
        xs={0}
        sm={0}
        md={0}
        lg={0}
        xl={6}
        xxl={6}
        style={{ height: '100%', width: '100%' }}
      >
        <Card style={{ width: '100%', height: '100%' }}>
          <Text type="paragraph">
            Por que é importante o cadastro completo do meu cliente?
          </Text>
          <Divider />
          <Text type="paragraph">
            Prev é a plataforma previdenciária mais moderna e mais segura do
            mercado!
          </Text>
          <Text>
            Aqui seus dados são protegidos, além disso não usaremos seu email
            para enviar promoções, usaremos somente para envio dos emails
            transacionais e de atualizações da plataforma.
          </Text>
        </Card>
      </Col>
    </Row>
  )
}
