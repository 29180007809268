// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.customTableRow:hover {
    background-color: #f5f5f5; /* your preferred color or style */
    cursor: pointer;
}

:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background-color: transparent;
}

:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background-color: transparent;
}

:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined {
    background-color: transparent;
}

:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {
    background-color: transparent;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:valid,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-transition-delay: 99999s;
}

:where(.css-dev-only-do-not-override-1bw5nkn).ant-form-item {
    margin-bottom: 0;
}

:where(.css-dev-only-do-not-override-epjhpw).ant-form-item {
    margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/reset.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB,EAAE,kCAAkC;IAC7D,eAAe;AACnB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;AACjC;;;AAGA;;;;;;;;IAQI,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\n.customTableRow:hover {\n    background-color: #f5f5f5; /* your preferred color or style */\n    cursor: pointer;\n}\n\n:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {\n    background-color: transparent;\n}\n\n:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {\n    background-color: transparent;\n}\n\n:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined {\n    background-color: transparent;\n}\n\n:where(.css-dev-only-do-not-override-1bw5nkn).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {\n    background-color: transparent;\n}\n\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active,\ninput:-webkit-autofill:valid,\nselect:-webkit-autofill,\nselect:-webkit-autofill:hover,\nselect:-webkit-autofill:focus {\n    -webkit-transition-delay: 99999s;\n}\n\n:where(.css-dev-only-do-not-override-1bw5nkn).ant-form-item {\n    margin-bottom: 0;\n}\n\n:where(.css-dev-only-do-not-override-epjhpw).ant-form-item {\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
