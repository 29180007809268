import { createContext, useContext, useState } from 'react'
import { useSpring, animated, type SpringValue } from 'react-spring'
import { CreateClientSheet } from '../components/clientSheet/CreateClientSheet'
import { Col, Form, Button, usePrevTheme } from '@prev-front/ui-components'
import { Navbar } from '../components/ui/Navbar'
import { CreateClientForm } from '../components/createClient/CreateClientForm'
import { ClientFooter } from '../components/ui/Footer'
import { type AttendanceSheet, type NewClient } from '../services'
import { ClientContext } from './ClientContext'
import { downloadPdf } from '@prev-front/api-adapter'
import { renderToString } from 'react-dom/server'
import { ClientSheetPrint } from '../utils/ClientSheetPrint'
import moment from 'moment'
import { NotificationContext } from './NotificationContext'
import { ClientContracts } from '../components/clientContracts/ClientContracts'

interface AnimationHandlerInterface {
  createClient: {
    createClientX: SpringValue<number>
    isVisibleCreate: boolean
    setIsVisibleCreate: React.Dispatch<React.SetStateAction<boolean>>
  }
  createSheet: {
    clientSheetX: SpringValue<number>
    isVisibleSheet: boolean
    setIsVisibleSheet: React.Dispatch<React.SetStateAction<boolean>>
  }
  createContract: {
    createContractX: SpringValue<number>
    isVisibleContract: isVisibleContract
    setIsVisibleContract: React.Dispatch<
      React.SetStateAction<isVisibleContract>
    >
  }
}

interface isVisibleContract {
  visible: boolean
  cpf: string | undefined
  editorContent: string | undefined
}

export const AnimationHandlerContext = createContext<AnimationHandlerInterface>(
  {} as any
)

export const AnimationHandlerProvider = ({ children }): JSX.Element => {
  const [isVisibleSheet, setIsVisibleSheet] = useState(false)
  const [isVisibleCreate, setIsVisibleCreate] = useState(false)
  const [isVisibleContract, setIsVisibleContract] = useState<isVisibleContract>(
    { visible: false, cpf: undefined, editorContent: undefined }
  )
  const { theme } = usePrevTheme()
  const [newClientForm] = Form.useForm<NewClient>()
  const [sheetForm] = Form.useForm<AttendanceSheet>()
  const { clientData } = useContext(ClientContext)
  const { api } = useContext(NotificationContext)

  const resizeObserver = new ResizeObserver(() => {
    window.outerWidth = window.innerWidth
  })

  resizeObserver.observe(document.documentElement)

  const { x: clientSheetX } = useSpring({
    x: isVisibleSheet ? 0 : -window.innerWidth
  })

  const { x: createClientX } = useSpring({
    x: isVisibleCreate ? 0 : -window.innerWidth
  })

  const { x: createContractX } = useSpring({
    x: isVisibleContract.visible ? 0 : -window.innerWidth
  })

  const value = {
    createClient: {
      createClientX,
      setIsVisibleCreate,
      isVisibleCreate
    },
    createSheet: {
      isVisibleSheet,
      clientSheetX,
      setIsVisibleSheet
    },
    createContract: {
      isVisibleContract,
      createContractX,
      setIsVisibleContract
    }
  }

  const onCloseClienteCreate = (): void => {
    if (clientData) {
      newClientForm.setFieldsValue(clientData)
    }
    setIsVisibleCreate(false)
  }

  const onCloseSheet = (): void => {
    if (clientData?.fichaDeAtendimento) {
      sheetForm.setFieldsValue(clientData.fichaDeAtendimento)
    }
    setIsVisibleSheet(false)
  }

  const onCloseCreateContract = (): void => {
    setIsVisibleContract({
      cpf: undefined,
      visible: false,
      editorContent: undefined
    })
  }

  const printSheet = async (): Promise<void> => {
    try {
      const html = renderToString(<ClientSheetPrint cliente={clientData} />)
      const response = await downloadPdf({
        filename: `Ficha-${clientData?.nome}-${moment().format('DD-MM-Y')}.pdf`,
        html,
        params: {
          footerCenter: '[page]/[toPage]',
          footerSpacing: 10,
          footerFontSize: 8
        }
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Ficha-${clientData?.nome}-${moment().format('DD-MM-Y')}.pdf` ||
          'file.pdf'
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (err) {
      api.error(({
        message: err.message || "Algo deu errado ao tentar imprimir, tente novamente mais tarde",
        placement: "topRight"
      }))
    }
  }

  const printContract = (document: string): void => {
    const w = window.open()
    w?.document.write(
      '<style>@page { size: auto;  margin: 0mm; } body {padding: 0px}</style>' +
        document
    )
    w?.print()
    w?.close()
  }

  return (
    <AnimationHandlerContext.Provider value={value}>
        {children}
        <animated.div
            className="content"
            style={{
              transform: clientSheetX.to(x => `translate3d(${x * -1}px, 0, 0)`),
              position: 'fixed',
              display: isVisibleSheet ? 'block' : 'none',
              top: 0,
              left: 0,
              zIndex: 50,
              width: '100vw',
              height: '100vh',
              padding: '20px',
              backgroundColor: theme.color.surface.container.default,
              overflowY: 'scroll'
            }}
        >
          <Col>
            <Navbar title={clientData?.fichaDeAtendimento ? 'Editar ficha' : 'Gerar ficha'} onClose={onCloseSheet} buttons={[
              <Button key={'printButton'} type='primary' onClick={printSheet} style={{ color: "white"}}>Imprimir</Button>
            ]}/>
            <CreateClientSheet form={sheetForm}/>
            <ClientFooter form={sheetForm} onClose={onCloseSheet} />
          </Col>
        </animated.div>
        <animated.div
            className="content"
            style={{
              transform: createClientX.to(x => `translate3d(${x * -1}px, 0, 0)`),
              position: 'fixed',
              display: isVisibleCreate ? 'block' : 'none',
              top: 0,
              left: 0,
              zIndex: 50,
              width: '100vw',
              height: '100vh',
              padding: '20px',
              backgroundColor: theme.color.surface.container.default,
              overflowY: 'scroll'
            }}>
            <Col style={{ position: 'relative', backgroundColor: theme.color.surface.container.default }}>
                <Navbar title={clientData ? "Editar cliente" : "Adicionar novo cliente"} onClose={onCloseClienteCreate}/>
                <CreateClientForm form={newClientForm} />
                <ClientFooter form={newClientForm} onClose={onCloseClienteCreate} />
            </Col>
        </animated.div>
        <animated.div
            className="content"
            style={{
              transform: createContractX.to(x => `translate3d(${x * -1}px, 0, 0)`),
              position: 'fixed',
              display: isVisibleContract.visible ? 'block' : 'none',
              top: 0,
              left: 0,
              zIndex: 50,
              width: '100vw',
              height: '100vh',
              padding: '20px 20px 0 20px',
              backgroundColor: theme.color.surface.container.default,
              overflowY: 'scroll'
            }}>
            <Col style={{ position: 'relative', display: 'flex', flexDirection: 'column', backgroundColor: theme.color.surface.container.default, height: '100%' }}>
                <Navbar title='Gerar contrato' onClose={onCloseCreateContract} buttons={[
                  isVisibleContract.editorContent ? <Button key={'printButton'} type='primary' style={{ color: 'white' }} onClick={() => { printContract(isVisibleContract?.editorContent ?? '') }}>Imprimir</Button> : null
                ]}
                />
                <ClientContracts />
            </Col>
        </animated.div>
    </AnimationHandlerContext.Provider>
  )
}
