import { useEffect, useState } from 'react'
import {
  type ClientData,
  getClientData,
  getHistory,
  type PaginatedHistResponse,
  getCalcs,
  type GetCalcsResponse,
  getClientPetitions,
  type GetCLientPetitionsResponse,
  getClientUploads,
  type GetClientUploadsResponse
} from '../services'

import { message } from '@prev-front/ui-components'

export interface UseClientDataInterface {
  clientData: ClientData | undefined
  isLoading: boolean
  history: PaginatedHistResponse | undefined
  fetchHistory: (props: FetchHistoryProps) => Promise<void>
  fetchClient: () => Promise<void>
  clientCalcs: GetCalcsResponse[] | []
  fetchCalcs: () => Promise<void>
  clientPetitions: GetCLientPetitionsResponse[]
  fetchPetitions: () => Promise<void>
  clientUploads: GetClientUploadsResponse[]
  fetchUploads: () => Promise<void>
  uploadModal: boolean
  setUploadModal: (value: boolean) => void
  obsModal: boolean
  setObsModal: (value: boolean) => void
}

export interface FetchHistoryProps {
  cpf: string
  limite: number
  pagina: number
}

export const useClientData = (cpf: string | undefined): UseClientDataInterface => {
  const [clientData, setClientData] = useState<ClientData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [history, setHistory] = useState<PaginatedHistResponse>()
  const [clientCalcs, setClientCalcs] = useState<GetCalcsResponse[] | []>([])
  const [clientPetitions, setClientPetitions] = useState<GetCLientPetitionsResponse[]>([])
  const [clientUploads, setClientUploads] = useState<GetClientUploadsResponse[]>([])
  const [uploadModal, setUploadModal] = useState<boolean>(false)
  const [obsModal, setObsModal] = useState(false)

  const fetchHistory = async (props: FetchHistoryProps): Promise<void> => {
    const { cpf, limite, pagina } = props
    try {
      if (!cpf) return
      const data = await getHistory({ cpf, limite, pagina })
      setHistory(data)
    } catch (err) {
      void message.error('Erro ao buscar histórico do cliente')
    }
  }

  const fetchUploads = async (): Promise<void> => {
    const data = await getClientUploads({
      cpf: cpf ?? ''
    })
    setClientUploads(data)
  }

  const fetchCalcs = async (): Promise<void> => {
    const data = await getCalcs({
      clientId: clientData?.uuid ?? '',
      limit: 10
    })
    setClientCalcs(data)
  }

  const fetchPetitions = async (): Promise<void> => {
    const data = await getClientPetitions({
      cpf: cpf ?? ''
    })
    setClientPetitions(data)
  }

  const fetchClient = async (): Promise<void> => {
    if (!cpf) {
      void message.error('Cliente não encontrado')
      return
    }
    setIsLoading(true)
    const data = await getClientData(cpf)
    setClientData(data)
    setIsLoading(false)
  }

  useEffect(() => {
    void fetchClient()
    void fetchPetitions()
    void fetchUploads()
    void fetchHistory({
      cpf: cpf ?? '',
      limite: 10,
      pagina: 1
    })
  }, [])

  useEffect(() => {
    if (clientData?.uuid) void fetchCalcs()
  }, [clientData])

  return {
    clientData,
    isLoading,
    history,
    fetchHistory,
    fetchClient,
    clientCalcs,
    fetchCalcs,
    clientPetitions,
    fetchPetitions,
    clientUploads,
    fetchUploads,
    uploadModal,
    setUploadModal,
    obsModal,
    setObsModal
  }
}
