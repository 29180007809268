import { createContext } from 'react'
import { type UseClientsInterface, useClients } from '../hooks/useClients'

export const ClientListContext = createContext<UseClientsInterface>({} as UseClientsInterface)

export const ClientListProvider = ({ children }): JSX.Element => {
  return (
    <ClientListContext.Provider value={{ ...useClients() }}>
        {children}
    </ClientListContext.Provider>
  )
}
