import React from 'react'
import moment from 'moment'
// import 'moment/locale/pt-br'
import { escolaridade } from '.'

moment.locale('pt-br')

const getEnderecoFull = (enderecoPrincipal): string => {
  if (!enderecoPrincipal?.endereco) return '-'
  return `${enderecoPrincipal.endereco}, 
            ${enderecoPrincipal.numero} 
            ${
              enderecoPrincipal.complemento
                ? `, ${enderecoPrincipal.complemento}`
                : ''
            }, 
            ${enderecoPrincipal.bairro}, 
            ${enderecoPrincipal.cidade}/${enderecoPrincipal.uf}, 
            CEP ${enderecoPrincipal.cep}`
}

const calculateAge = (birthday): number => {
  const ageDifMs = Date.now() - new Date(birthday).getTime()
  const ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

const renderResponsaveis = (cliente): JSX.Element | null => {
  if (!cliente.fichaDeAtendimento?.responsaveis) return null
  const responsaveis = (
    <ul>
      {cliente.fichaDeAtendimento.responsaveis.map((responsavel, i) => {
        return <li key={i}>{responsavel}</li>
      })}
    </ul>
  )
  return responsaveis
}

const getSimNao = (tipo): string => {
  if (tipo === true) {
    return 'SIM'
  } else if (tipo === false) {
    return 'NÃO'
  } else {
    return ' -'
  }
}

export const ClientSheetPrint = (props): JSX.Element => {
  if (props.cliente) {
    const { cliente } = props
    return (
      <div style={{ background: '#fff' }}>
        {/* {relatorioHeaderInfo('Clique aqui para imprimir ou salvar a ficha do cliente em PDF')} */}
        <div className="flexCenter">
          <b>{props.cliente.nome}</b>
        </div>
        <div className="flexCenter mt40">
          <div style={{ width: 1000 }}>
            <div style={{ height: 205 }}>
              <div className="mt40 flexContainer">
                <div className="barraLateral" />
                <div className="ml20 flex">
                  <div className="flexContainer">
                    <div className="flex">
                      <div>Segurado</div>
                      <div>{props.cliente.nome || '-'}</div>
                    </div>
                    <div className="flex"></div>
                    <div className="flex">
                      <div>CPF</div>
                      <div>{props.cliente.cpf}</div>
                    </div>
                  </div>
                  <div className="flexContainer mt20">
                    <div className="flex">
                      <div>Data de nascimento</div>
                      <div>
                        {moment(props.cliente.dataDeNascimento).format(
                          'DD/MM/Y'
                        )}{' '}
                        ({calculateAge(props.cliente.dataDeNascimento)}) anos
                      </div>
                    </div>
                    <div className="flex">
                      <div>Sexo</div>
                      <div>{props.cliente.sexo || '-'}</div>
                    </div>
                    <div className="flex">
                      <div>RG</div>
                      <div>{props.cliente.rg || '-'}</div>
                    </div>
                  </div>
                  <div className="flexContainer mt20">
                    <div className="flex">
                      <div>Profissão</div>
                      <div>{props.cliente.profissao || '-'}</div>
                    </div>
                    <div className="flex">
                      <div>Estado civil</div>
                      <div>{props.cliente.estadoCivil || '-'}</div>
                    </div>
                    <div className="flex">
                      <div>Escolaridade</div>
                      <div>
                        {props.cliente.escolaridade
                          ? escolaridade.find(
                              (e) => e.value === props.cliente.escolaridade
                            )?.desc
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="flexContainer mt20">
                    <div className="flex">
                      <div>Nacionalidade</div>
                      <div>{props.cliente.nacionalidade || '-'}</div>
                    </div>
                    <div className="flex"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt20" style={{ fontSize: 18 }}>
              Dados de contato
            </div>
            <div style={{ height: 112 }}>
              <div className="mt40 flexContainer">
                <div className="barraLateral" />
                <div className="ml20 flex">
                  <div className="flexContainer mt20">
                    <div className="flex">
                      <div>E-mail</div>
                      <div>{props.cliente.email || '-'}</div>
                    </div>
                    <div className="flex">
                      <div>Telefone</div>
                      <div>{props.cliente.telefonePrincipal || '-'}</div>
                    </div>
                    <div className="flex">
                      <div>Celular</div>
                      <div>{props.cliente.celular || '-'}</div>
                    </div>
                  </div>
                  <div className="flexContainer mt20">
                    <div className="flex">
                      <div>Endereço</div>
                      <div>
                        {props.cliente.endereco
                          ? getEnderecoFull(props.cliente.endereco)
                          : '-'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt20" style={{ fontSize: 18 }}>
              Contatos autorizados
            </div>
            <div>
              <div className="mt40 flexContainer">
                <div className="barraLateral" />
                <div className="flex">{renderResponsaveis(props.cliente)}</div>
              </div>
            </div>

            <div className="mt20" style={{ fontSize: 18 }}>
              Questionário
            </div>
            <div>
              <div className="mt40 flexContainer">
                <div className="barraLateral" />
                <div className="ml20 flex">
                  <div className="flexContainer mt20">
                    <div className="flex">
                      <div className="mb10">Rural</div>
                      <div>
                        Nasceu em zona rural e trabalhou em regime de economia
                        familiar?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento
                              ?.nasceuZonaRuralEconomiaFamiliar
                          )}
                        </b>
                      </div>
                      <div>
                        Se sim, quantos hectares possuía?
                        <b>
                          {cliente?.fichaDeAtendimento
                            ?.nasceuZonaRuralEconomiaFamiliar
                            ? cliente?.fichaDeAtendimento?.totalDeHectares
                            : ' -'}
                        </b>
                      </div>
                      <div>
                        Possuía empregados? Quantos?
                        <b>
                          {cliente?.fichaDeAtendimento
                            ?.nasceuZonaRuralEconomiaFamiliar
                            ? cliente.fichaDeAtendimento.totalDeEmpregados
                            : ' -'}
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className="flexContainer mt40">
                    <div className="flex">
                      <div className="mb10">Exército, Escola, Aprendiz</div>
                      <div>
                        Serviu ao Exército?{' '}
                        <b>
                          {getSimNao(cliente.fichaDeAtendimento?.serviuExercito)}
                        </b>
                      </div>
                      <div>
                        Fez escola técnica?{' '}
                        <b>
                          {getSimNao(
                            cliente.fichaDeAtendimento?.fezEscolaTecnica
                          )}
                        </b>
                      </div>
                      <div>
                        Foi aluno aprendiz?{' '}
                        <b>
                          {getSimNao(
                            cliente.fichaDeAtendimento?.foiJovemAprendiz
                          )}
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className="flexContainer mt40">
                    <div className="flex">
                      <div className="mb10">Serviço Público</div>
                      <div>
                        Exerceu período de Serviço Público Concursado?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento
                              ?.exerceuServicoPublicoConcursado
                          )}
                        </b>
                      </div>
                      <div>
                        Teve certidão de tempo de contribuição emitida no
                        Serviço Público?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento
                              ?.teveCertidaoDeTempoDeContribuicao
                          )}
                        </b>
                      </div>
                      <div>
                        Teve algum contrato de experiência?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento?.teveContratoDeExperiencia
                          )}
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className="flexContainer mt40">
                    <div className="flex">
                      <div className="mb10">Atividade Especial</div>
                      <div>
                        Esteve sujeito à risco de saúde ou integridade física na
                        profissão?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento
                              ?.teveRiscoDeSaudeOuIntegridadeFisica
                          )}
                        </b>
                      </div>
                      <div>
                        Tem algum formulário que comprove atividade especial:{' '}
                        <b>
                          {cliente?.fichaDeAtendimento?.formulariosDeAtividadeEspecial?.join(
                            ', '
                          )}
                        </b>
                      </div>
                      <div>
                        Algum colega de trabalho conseguiu reconhecer atividade
                        especial?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento
                              ?.algumColegaDeTrabalhoReconheceuAtividadeEspecial
                          )}
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className="flexContainer mt40">
                    <div className="flex">
                      <div className="mb10">
                        Autônomo / Contribuinte Individual
                      </div>
                      <div>
                        Trabalhou como autônomo ou contribuinte individual?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento
                              ?.foiAutonomoOuContribuinteIndividual
                          )}
                        </b>
                      </div>
                      {cliente?.fichaDeAtendimento
                        ?.foiAutonomoOuContribuinteIndividual && (
                        <div>
                          Documentos que comprovam:{' '}
                          <b>
                            {cliente?.fichaDeAtendimento?.documentosContribuinteIndividual?.join(
                              ', '
                            )}
                          </b>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flexContainer mt40">
                    <div className="flex">
                      <div className="mb10">Benefício previdenciário</div>
                      <div>
                        Recebeu ou pediu benefício previdenciário?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento?.recebeuOuPediuBeneficio
                          )}
                        </b>
                      </div>
                      <div>
                        Possui cópia do processo administrativo?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento
                              ?.temCopiaDoProcessoAdministrativo
                          )}
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className="flexContainer mt40">
                    <div className="flex">
                      <div className="mb10">Processo</div>
                      <div>
                        Já teve processo judicial trabalhista?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento
                              ?.teveProcessoJudicialTrabalhista
                          )}
                        </b>
                      </div>
                      <div>
                        Já teve processo judicial previdenciário?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento
                              ?.teveProcessoJudicialPrevidenciario
                          )}
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className="flexContainer mt40">
                    <div className="flex">
                      <div className="mb10">Exterior</div>
                      <div>
                        Já trabalhou fora do Brasil?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento?.trabalhouForaDoBrasil
                          )}
                        </b>
                      </div>
                      <div>Em qual país? Não aplicável </div>
                      <div>Em que período? Não aplicável </div>
                    </div>
                  </div>
                  <div className="flexContainer mt40">
                    <div className="flex">
                      <div className="mb10">Outros</div>
                      <div>
                        Já recolheu contribuição em GPS (carnê)?{' '}
                        <b>
                          {getSimNao(cliente?.fichaDeAtendimento?.recolheuGPS)}
                        </b>
                      </div>
                      <div>
                        Sofre ou sofreu de doença que inviabilize ou dificulte o
                        trabalho?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento
                              ?.sofreOuSofreuDoencaQueDificultaTrabalhar
                          )}
                        </b>
                      </div>
                      <div>
                        Já trabalhou como pessoa com deficiência?{' '}
                        <b>
                          {getSimNao(
                            cliente?.fichaDeAtendimento
                              ?.trabalhouComoPessoaComDeficiencia
                          )}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt40" style={{ fontSize: 18 }}>
              Declaração
            </div>
            <div>
              Eu, {cliente?.nome}, CPF nº {cliente?.cpf}, declaro para os devidos
              fins e sob as penas da lei que todos os dados prestados acima são
              verdadeiros.
            </div>
            <div className="flexCenter" style={{ marginTop: 50 }}>
              <div className="flexContainerColumnFull">
                <div style={{ textAlign: 'center' }}>
                  ______________, {moment().format('DD')} de{' '}
                  {moment().format('MMMM')} de {moment().format('Y')}
                </div>
                <div
                  style={{
                    height: 50,
                    marginBottom: 15,
                    borderBottom: '1px solid black',
                    width: 300
                  }}
                ></div>
                <div style={{ textAlign: 'center' }}>{cliente?.nome}</div>
                <div style={{ textAlign: 'center' }}>{cliente?.cpf}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <div></div>
}
