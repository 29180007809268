import { FileProtectOutlined } from '@ant-design/icons'
import {
  Card,
  Col,
  LocalLoading,
  Row,
  Text,
  TinyMceEditor,
  usePrevTheme
} from '@prev-front/ui-components'
import { useContext, useRef, useState } from 'react'
import { AnimationHandlerContext } from '../../context/AnimationHandlerContext'
import { getClientContract } from '../../services'
import { NotificationContext } from '../../context/NotificationContext'
import { ApiError } from '@prev-front/api-adapter'

interface EditorProps {
  getContent: () => string
}

const styleFormats = [
  {
    title: 'Texto normal',
    block: 'p',
    format: 'textonormal',
    classes: 'textonormal'
  },
  {
    title: 'Nome da ação',
    block: 'h2',
    format: 'nomeacao',
    classes: 'nomeacao'
  },
  {
    title: 'Endereçamento',
    block: 'p',
    format: 'enderecamento',
    classes: 'enderecamento'
  },
  {
    title: 'Ementa Petição',
    block: 'p',
    format: 'ementa',
    classes: 'ementa'
  },
  {
    title: 'Qualificação',
    block: 'p',
    format: 'qualificacao',
    classes: 'qualificacao'
  },
  {
    title: 'Tópico 1',
    block: 'h2',
    format: 'topico1',
    classes: 'topico1'
  },
  {
    title: 'Tópico 2',
    block: 'h3',
    format: 'topico2',
    classes: 'topico2'
  },
  {
    title: 'Tópico 3',
    block: 'h4',
    format: 'topico3',
    classes: 'topico3'
  },
  {
    title: 'Tópico 4',
    block: 'h5',
    format: 'topico4',
    classes: 'topico4'
  },
  {
    title: 'Citação',
    block: 'p',
    format: 'citacao',
    classes: 'citacao'
  },
  {
    title: 'Data, local e assinatura',
    block: 'p',
    format: 'dataassinatura',
    classes: 'dataassinatura'
  }
]

export const ClientContracts = (): JSX.Element => {
  const { theme } = usePrevTheme()
  const { createContract } = useContext(AnimationHandlerContext)
  const [isLoading, setIsLoading] = useState(false)
  const Editor = useRef<EditorProps>()
  const { api } = useContext(NotificationContext)

  const getContract = async (type: string): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await getClientContract({
        cpf: createContract.isVisibleContract.cpf ?? '',
        tipo: type
      })
      createContract.setIsVisibleContract({
        ...createContract.isVisibleContract,
        editorContent: response.content
      })
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      if (err instanceof ApiError) {
        api.error({
          message: err.message,
          placement: 'topRight'
        })
      }
      if (!(err instanceof ApiError)) {
        api.error({
          message: 'Algo deu errado, tente novamente mais tarde',
          placement: 'topRight'
        })
      }
    }
  }

  if (isLoading) {
    return (
      <Col style={{ height: '100vh', width: '100vw' }}>
        <LocalLoading display={isLoading} />
      </Col>
    )
  }

  if (createContract?.isVisibleContract?.editorContent) {
    return (
      <TinyMceEditor
      /* @ts-expect-error todo bug no prev/uikit */
        editorBaseUrl="https://prevfeature.app/assets/tinymce"
        ref={Editor}
        styleFormats={styleFormats}
        toolbarHeight={'34px'}
        footerHeight={32}
        headerHeight={112}
        initialContent={createContract?.isVisibleContract?.editorContent}
      />
    )
  }

  return (
    <Col style={{ padding: '0 130px', height: '100%' }}>
      <Text emphasis>Escolha um tipo de contrato para começar</Text>
      <Text color={theme.color.text.subtle}>
        Disponibilizamos alguns contratos prontos que ajudam muito no dia a dia,
        então basta escolher o tipo do contrato e imprimir o mesmo.{' '}
      </Text>
      <Row style={{ gap: '20px', marginTop: '30px' }}>
        <Col style={{ width: '300px', height: '100px' }}>
          <Card
            style={{ cursor: 'pointer' }}
            /* @ts-expect-error erro props card */
            onClick={async () => {
              await getContract('PROCURACAO_JUDICIAL')
            }}
          >
            <FileProtectOutlined
              style={{ color: theme.color.text.brand, fontSize: '32px' }}
            />
            <Text style={{ margin: '10px 0' }}>Procuração judicial</Text>
            <Text style={{ color: theme.color.text.brand }}>
              Editar contrato
            </Text>
          </Card>
        </Col>
        <Col style={{ width: '300px' }}>
          <Card
            style={{ cursor: 'pointer' }}
            /* @ts-expect-error erro props card */
            onClick={async () => {
              await getContract('PROCURACAO_ADMINISTRATIVO')
            }}
          >
            <FileProtectOutlined
              style={{ color: theme.color.text.brand, fontSize: '32px' }}
            />
            <Text style={{ margin: '10px 0' }}>Procuração administrativa</Text>
            <Text style={{ color: theme.color.text.brand }}>
              Editar contrato
            </Text>
          </Card>
        </Col>
        <Col style={{ width: '300px' }}>
          <Card
            style={{ cursor: 'pointer' }}
            /* @ts-expect-error erro props card */
            onClick={async () => {
              await getContract('HONORARIO')
            }}
          >
            <FileProtectOutlined
              style={{ color: theme.color.text.brand, fontSize: '32px' }}
            />
            <Text style={{ margin: '10px 0' }}>Contrato de honorários</Text>
            <Text style={{ color: theme.color.text.brand }}>
              Editar contrato
            </Text>
          </Card>
        </Col>
      </Row>
    </Col>
  )
}
