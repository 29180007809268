import { createContext } from 'react'
import { type UseClientDataInterface, useClientData } from '../hooks/useClientData'
import { useParams } from 'react-router-dom'

export const ClientContext = createContext<UseClientDataInterface>({} as any)

export const ClientProvider = ({ children }): JSX.Element => {
  const { cpf } = useParams()
  return (
    <ClientContext.Provider value={{ ...useClientData(cpf) }}>
        {children}
    </ClientContext.Provider>
  )
}
