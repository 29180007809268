// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customTableRow:hover {
    background-color: transparent;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover {
    background-color: transparent !important;
}

.ant-table-wrapper .ant-table {
    background-color: transparent;
}

.ant-form-item .ant-form-item-control-input {
    min-height: 0;
}

.ant-timeline .ant-timeline-item-head {
    background-color: transparent;
}

.ant-timeline .ant-timeline-item-content {
    margin-inline-start: 40px
}`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI;AACJ","sourcesContent":[".customTableRow:hover {\n    background-color: transparent;\n}\n\n.ant-table-wrapper .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover {\n    background-color: transparent !important;\n}\n\n.ant-table-wrapper .ant-table {\n    background-color: transparent;\n}\n\n.ant-form-item .ant-form-item-control-input {\n    min-height: 0;\n}\n\n.ant-timeline .ant-timeline-item-head {\n    background-color: transparent;\n}\n\n.ant-timeline .ant-timeline-item-content {\n    margin-inline-start: 40px\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
