import { type AxiosResponse, authGetWithHeader, authPost, authGet, authPut, authDelete, PrevAxios, getToken } from '@prev-front/api-adapter'
import QueryString from 'qs'

import { API_HOST_DASHBOARD_V2 } from "./constants"

interface GetClientsProps {
  limite: number
  query?: string
  ordenaPor: string
  pagina: number
  token: string
}

export const getClients = async (props: GetClientsProps): Promise<any> => {
  const { limite, ordenaPor, pagina, query } = props
  const response = await authGetWithHeader(
    `/clientes?limite=${limite}&ordenaPor=${ordenaPor}&pagina=${pagina}&q=${query}`, API_HOST_DASHBOARD_V2
  )
  return response as AxiosResponse
}

interface Endereco {
  cep: string
  endereco: string
  cidade: string
  complemento: string
  uf: string
  bairro: string
  numero: string
}

export interface Profissao {
  codigo: string
  titulo: string
  condicoesGeraisDoExercicio: string
  descricaoSumaria: string
}

export interface NewClient {
  nome: string
  cpf: string
  estadoCivil: 'SOLTEIRO' | 'CASADO' | 'VIUVO' | 'DIVORCIADO' | 'SEPARADO'
  sexo: 'MASCULINO' | 'FEMININO'
  dataDeNascimento: string
  fichaDeAtendimento?: AttendanceSheet
  profissoes?: Profissao[]
  email?: string
  telefonePrincipal?: string
  celular?: string
  telefoneSecundario?: string
  endereco?: Endereco
  escolaridade?: 'ENSINO_FUNDAMENTAL_INCOMPLETO' | 'ENSINO_FUNDAMENTAL_COMPLETO' | 'ENSINO_MEDIO_INCOMPLETO' | 'ENSINO_MEDIO_COMPLETO' | 'ENSINO_SUPERIOR_INCOMPLETO' | 'ENSINO_SUPERIOR_COMPLETO' // Adicione ou remova conforme necessário
  ctps?: string
  nit?: string
  dataDeObito?: string
  rg?: string
  cnh?: string
  nitSecundario?: string
}

export interface AttendanceSheet {
  id: number
  uuid: string
  nasceuZonaRuralEconomiaFamiliar: boolean
  totalDeHectares: number
  totalDeEmpregados: number
  serviuExercito: boolean
  fezEscolaTecnica: boolean
  foiJovemAprendiz: boolean
  exerceuServicoPublicoConcursado: boolean
  teveCertidaoDeTempoDeContribuicao: boolean
  teveContratoDeExperiencia: boolean
  teveRiscoDeSaudeOuIntegridadeFisica: boolean
  descricaoRiscoDeSaudeOuIntegridadeFisica: string
  algumColegaDeTrabalhoReconheceuAtividadeEspecial: boolean
  foiAutonomoOuContribuinteIndividual: boolean
  teveProcessoJudicialTrabalhista: boolean
  teveProcessoJudicialPrevidenciario: boolean
  recebeuOuPediuBeneficio: boolean
  temCopiaDoProcessoAdministrativo: boolean
  trabalhouForaDoBrasil: boolean
  recolheuGPS: boolean
  trabalhouComoPessoaComDeficiencia: boolean
  sofreOuSofreuDoencaQueDificultaTrabalhar: boolean
  responsaveis: string[]
}

export interface ClientData extends NewClient {
  id: number
  uuid: string
  dataDeAtualizacao: string
}

export const getClientData = async (cpf: string): Promise<ClientData> => {
  const data = await authGet(`/clientes/${cpf}`, API_HOST_DASHBOARD_V2)
  return data as ClientData
}

export const createClient = async (payload: NewClient): Promise<any> => {
  console.log(payload)
  const response = await authPost({
    path: '/clientes',
    data: {
      ...payload
    },
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

interface GetHistoryProps {
  cpf: string
  limite: number
  pagina: number
}

interface CriadoPor {
  id: string
}

export interface HistoryEvent {
  id: string
  clienteId: string
  tipo: string
  categoria: string
  mensagem: string
  criadoPor: CriadoPor
  destinadoPara: Record<string, unknown>
  dataDeCriacao: string
  dataDaUltimaAtualizacao: string
  respostas: any[]
}

export interface PaginatedHistResponse {
  total: number
  totalDePaginas: number
  proximo: number
  eventos: HistoryEvent[]
}

export const getHistory = async (props: GetHistoryProps): Promise<PaginatedHistResponse> => {
  const { cpf, limite, pagina } = props
  const response = await authGet(
    `/clientes/${cpf}/historico?limite=${limite}&pagina=${pagina}`, API_HOST_DASHBOARD_V2
  )
  return response as PaginatedHistResponse
}

interface PostHistoryMessageProps {
  cpf: string
  mensagem: string
}

export const postHistoryMessage = async (props: PostHistoryMessageProps): Promise<HistoryEvent> => {
  const { cpf, mensagem } = props
  const response = await authPost({
    path: `/clientes/${cpf}/historico`,
    data: {
      mensagem
    },
    host: API_HOST_DASHBOARD_V2
  })
  return response as HistoryEvent
}

interface updateClientProps {
  cpf: string
  payload: any
}

export const updateClient = async (props: updateClientProps): Promise<any> => {
  const { cpf, payload } = props
  console.log("payload", payload)
  const response = await authPut({
    path: `/clientes/${cpf}`,
    data: {
      ...payload
    },
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

interface GetCalcsProps {
  clientId: string
  limit: number
}

export interface GetCalcsResponse {
  id: number
  uuid: string
  tipo: string
  nome: string
  dataDeCriacao: string
  carencia: number
  dataDeInscricao: string
}

export const getCalcs = async (props: GetCalcsProps): Promise<any> => {
  const { clientId, limit } = props
  const path = `/calculos?idDoCliente=${clientId}&limite${limit}`
  const response = await authGet(path, API_HOST_DASHBOARD_V2)
  return response as GetCalcsResponse[]
}

export const deleteCalc = async (calcId: number): Promise<any> => {
  const response = await authDelete({
    path: `/calculos/${calcId}`,
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

interface GetClientPetitonsProps {
  cpf: string
}

export interface GetCLientPetitionsResponse {
  id: string
  idDoModeloDaPeticao: string
  idDoCliente: string
  cpfDoCliente: string
  idDoEscritorio: number
  titulo: string
  resumo: string
  tipo: string
  tipoDeAcao: string
  tags: string[]
  criadoPor: string
  atualizadoPor: string
  dataDeCriacao: string
  dataDaUltimaAtualizacao: string
  sexo: 'MASCULINO' | 'FEMININO' // Assumindo que são as únicas opções
  tipoDeProcesso: string
  tiposDeBeneficio: string[]
  conteudo: string
}

export const getClientPetitions = async (props: GetClientPetitonsProps): Promise<GetCLientPetitionsResponse[]> => {
  const path = `/clientes-peticoes?cpf=${props.cpf}`
  const response = await authGet(path, API_HOST_DASHBOARD_V2)
  return response as GetCLientPetitionsResponse[]
}

export const deleteClientPetition = async (petitionId: string): Promise<any> => {
  const response = await authDelete({
    path: `/clientes-peticoes/${petitionId}`,
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

interface GetClientUploadsProps {
  cpf: string
}

export interface GetClientUploadsResponse {
  id: string
  clienteId: string
  nome: string
  nomeAws: string
  tipoArquivoDocumento: string
  tipoArquivoDocumentoDescricao: string
  url: string
  dataDeCriacao: string
  dataDeAtualizacao: string
}

export const getClientUploads = async (props: GetClientUploadsProps): Promise<GetClientUploadsResponse[]> => {
  const path = `/clientes-arquivos/${props.cpf}`
  const response = await authGet(path, API_HOST_DASHBOARD_V2)
  return response as GetClientUploadsResponse []
}

export const deleteClientUpload = async (uploadId: string): Promise<any> => {
  const response = await authDelete({
    path: `/clientes-arquivos/${uploadId}`,
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

export const deleteClient = async (cpf: string): Promise<any> => {
  const response = await authDelete({
    path: `/clientes/${cpf}`,
    host: API_HOST_DASHBOARD_V2
  })
  return response
}

export const downloadClientUpload = async (uploadId: string): Promise<Blob> => {
  const token = getToken()
  const response = await PrevAxios.get(`${API_HOST_DASHBOARD_V2}/clientes-arquivos/${uploadId}/download`, {
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return response.data as Blob
}

interface GetClientContractProps {
  cpf: string
  tipo: string
}

interface GetClientContractResponse {
  content: string
}

export const getClientContract = async (params: GetClientContractProps): Promise<GetClientContractResponse> => {
  const path = `/clientes/${params.cpf}/contratos/tipos/${params.tipo}`
  const response = await authGet(path, API_HOST_DASHBOARD_V2)
  return response as GetClientContractResponse
}

interface PostClientUploadProps {
  cpf: string
  payload: {
    tipoDocumento: string
    arquivo: File
    onUploadProgress?: (progressEvent: any) => void
  }
}
export const postClientUpload = async (props: PostClientUploadProps): Promise<any> => {
  const { cpf, payload } = props
  const form = new FormData()
  const token = getToken()
  form.append('tipoDocumento', payload.tipoDocumento)
  form.append('arquivo', payload.arquivo)

  await PrevAxios.post(`${API_HOST_DASHBOARD_V2}/clientes-arquivos/${cpf}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    },
    onUploadProgress (progressEvent) {
      if (payload.onUploadProgress) {
        payload.onUploadProgress(progressEvent)
      }
    }
  })
}

export const getEnderecoByCep = async (cep): Promise<any> => {
  return await authGet(`/utils/cep/${cep}`)
}

type Params = Record<string, any>

export interface JobsInterface {
  codigo: string
  titulo: string
  sinonimos: string[]
  condicoesGeraisDoExercicio: string
  descricaoSumaria: string
}

export async function getJobs (params: Params): Promise<JobsInterface[] | null> {
  try {
    const queryString = QueryString.stringify(params, { addQueryPrefix: true, skipNulls: true })
    const response = await authGet(`/profissoes${queryString}`, API_HOST_DASHBOARD_V2)
    if (response) {
      return response as JobsInterface[]
    } else {
      throw new Error('Resposta vazia do servidor.')
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}