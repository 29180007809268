import { Button, Row, usePrevTheme } from '@prev-front/ui-components'
import { useContext } from 'react'

interface ClientFooterProps {
  onClose: any
  form?: any
}

export const ClientFooter = (props: ClientFooterProps): JSX.Element => {
  const { form, onClose } = props
  const { theme } = usePrevTheme()

  return (
    <Row
      style={{
        width: '100%',
        height: '75px',
        zIndex: '99999',
        marginTop: '100px',
        bottom: 0,
        justifyContent: 'center',
        alignContent: 'center',
        borderTop: `1px solid ${theme.color.border.default}`
      }}
    >
      <Row
        style={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '10px 30px',
          width: '100%',
          gap: '20px',
          height: '100%',
          backgroundColor: theme.color.surface.container.default
        }}
      >
        <Button type="subtle" onClick={onClose}>
          Cancelar
        </Button>
        <Button type="primary" style={{ color: 'white' }} onClick={form.submit}>
          Salvar
        </Button>
      </Row>
    </Row>
  )
}
